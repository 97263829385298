<template>
  <div>
    <DonationBanner />
    <section class="hero">
      <h1>Easy to use, right off the tee!</h1>
    </section>
    <section class="contentToggler">
      <div
        class="toggler organiser"
        @click="toggleActiveSection($event)"
        :class="{ active: showOrganiserSection }"
      >
        <h3>Organiser</h3>
      </div>
      <div
        class="toggler player"
        @click="toggleActiveSection($event)"
        :class="{ active: showPlayerSection }"
      >
        <h3>Player</h3>
      </div>
    </section>
    <div class="togglableContent">
      <transition name="fade" mode="out-in">
        <WhyTjingOrganiser v-if="showOrganiserSection" />
      </transition>
      <transition name="fade" mode="out-in">
        <WhyTjingPlayer v-if="showPlayerSection" />
      </transition>
    </div>
    <SignUpCTA />
  </div>
</template>

<script>
import WhyTjingOrganiser from "@/components/WhyTjingOrganiser.vue";
import WhyTjingPlayer from "@/components/WhyTjingPlayer.vue";
import SignUpCTA from "@/components/SignUpCTA.vue";
import DonationBanner from "@/components/DonationBanner";
export default {
  name: "whytjing",
  components: {
    WhyTjingOrganiser,
    WhyTjingPlayer,
    SignUpCTA,
    DonationBanner,
  },
  data() {
    return {
      showOrganiserSection: true,
      showPlayerSection: false,
    };
  },
  computed: {},
  methods: {
    toggleActiveSection(event) {
      const elementClassList = event.target.closest(".toggler").classList;
      if (!elementClassList.contains("active")) {
        this.showOrganiserSection = !this.showOrganiserSection;
        this.showPlayerSection = !this.showPlayerSection;
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 80px;
  margin: 0px;
  @include Gilroy-Bold;
}
h3 {
  font-size: 48px;
  margin-bottom: 0;
}
.hero {
  text-align: center;
  background-color: rgba(180, 0, 104, 0.03);
  padding: 120px 0px 140px 0px;
  * {
    max-width: 92%;
    margin: 0 auto;
  }
  h1 {
    color: #b40068;
  }
}
.contentToggler {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  h3 {
    @include Gilroy-Regular;
    line-height: 64px;
    &:hover {
      cursor: pointer;
    }
  }

  & .toggler {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 4px;
      display: block;
      background: #b40068;
      /* transition: width 0.5s ease;
      -webkit-transition: width 0.5s ease; */
    }
  }

  & .toggler.active {
    &:after {
      width: 100%;
    }
    h3 {
      @include Gilroy-Bold;
    }
  }
  .toggler:nth-child(1) {
    margin-right: 160px;
    &.active:after {
      right: 0;
    }
    &:not(.active):after {
      right: 0;
    }
  }
}
.togglableContent {
  .fade-enter-active {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }
  .fade-leave-active {
    -webkit-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
  h3 {
    font-size: 26px;
    line-height: 42px !important;
  }
  .hero {
    padding: 52px 0px;
  }
  .contentToggler {
    padding-top: 32px;
    .organiser {
      margin-right: 15% !important;
    }
  }
}
</style>
