<template>
  <div>
    <section class="sectionIntro">
      <h5>
        Tjing is made for everyone. A competition and practice tool for pros,
        amateurs and recreational players, as well as casual rounds with
        friends.
        <br class="showOnDesktop" />
        <span class="bold">Everyone can play. Everywhere. For free.</span>
      </h5>
      <div v-if="!isMobilePlatform()" class="buttonSection">
        <a href="https://apps.apple.com/se/app/tjing/id1277060479">
          <img
            class="storeButtonImage"
            src="@/assets/img/AppleStoreButtonLogo.png"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
        >
          <img
            class="storeButtonImage"
            src="@/assets/img/GooglePlayButtonLogo.png"
          />
        </a>
      </div>
      <div v-if="isMobilePlatform()" class="buttonSectionDeviceSpecific">
        <a
          v-if="shouldShowIOSButton()"
          href="https://apps.apple.com/se/app/tjing/id1277060479"
        >
          <img
            class="storeButtonImage"
            src="@/assets/img/AppleStoreButtonLogo.png"
          />
        </a>
        <a
          v-if="shouldShowAndroidButton()"
          href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
        >
          <img
            class="storeButtonImage"
            src="@/assets/img/GooglePlayButtonLogo.png"
          />
        </a>
      </div>
    </section>
    <section class="scorecard withBackgroundColor">
      <h1>The Scorecard</h1>
      <h5>Unlike any scorecard.</h5>
      <img src="@/assets/img/LiveScoring–Mobile_New.png" />
    </section>
    <section class="dashboard">
      <h1>Player Dashboard</h1>
      <h5>
        Dive into your throws and enjoy stats and an overview of played and
        upcoming events.
      </h5>
      <div class="showOnDesktop dashboardImagesDesktop">
        <div class="dashboardFirstRow">
          <div class="left">
            <img src="@/assets/img/dashboardInsidePutts.png" />
          </div>
          <div class="middle">
            <img src="@/assets/img/dashboardFullCircle.png" />
          </div>
          <div class="right">
            <img src="@/assets/img/dashboardOutsidePutts.png" />
          </div>
        </div>
        <div class="dashboardSecondRow">
          <div class="left">
            <img src="@/assets/img/dashboardAce.png" />
          </div>
          <div class="middle">
            <img src="@/assets/img/dashboardFullScore.png" />
          </div>
          <div class="right">
            <img src="@/assets/img/dashboardEagles.png" />
          </div>
        </div>
      </div>
      <div class="showOnMobile dashboardImagesMobile">
        <img
          class="fullCircleImage"
          src="@/assets/img/dashboardFullCircle.png"
        />
        <div class="halfCirclesWrapper">
          <img src="@/assets/img/dashboardInsidePutts.png" />
          <img src="@/assets/img/dashboardOutsidePutts.png" />
        </div>
        <div class="aceEaglesWrapper">
          <img src="@/assets/img/dashboardAce.png" />
          <img src="@/assets/img/dashboardEagles.png" />
        </div>
        <img
          class="statsSummaryImage"
          src="@/assets/img/dashboardFullScore.png"
        />
      </div>
    </section>
    <section class="comparePlayersWrapper">
      <div class="comparePlayers">
        <div class="left">
          <h3>Compare</h3>
          <h5>
            Compare yourself to others, or others to others, on all events.
          </h5>
        </div>
        <div>
          <img src="@/assets/img/comparePlayers.png" />
        </div>
      </div>
    </section>
    <section class="testimonialContainer">
      <h1>What players say</h1>
      <div class="testimonials">
        <div class="testimonial">
          <img src="@/assets/img/quoteSign.svg" />
          <h5>
            Scoring is fast and intuitive and with the Verifier it is very
            unlikely for any mistakes.
          </h5>
          <p>Anders Pantzer, Järva DGK</p>
        </div>
        <div class="testimonial">
          <img src="@/assets/img/quoteSign.svg" />
          <h5>
            Easy to use and easy to learn. I’m using the stats every time just
            so I can see where I need to improve.
          </h5>
          <p>Robin Villman, Falu DGK</p>
        </div>
        <div class="testimonial">
          <img src="@/assets/img/quoteSign.svg" />
          <h5>
            The best thing that happened to Swedish competition disc golf. I see
            no way back to analog scorecards.
          </h5>
          <p>Kristian Bengtsson, 360º DGK</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "WhyTjingPlayer",
  data() {
    return {
      dataFromUserPlatform: "",
    };
  },
  computed: {},
  methods: {
    isMobilePlatform() {
      return /iPhone|iPad|iPod|Android/i.test(this.dataFromUserPlatform);
    },
    shouldShowAndroidButton() {
      if (this.isMobilePlatform) {
        return /iPhone|iPad|iPod/i.test(this.dataFromUserPlatform)
          ? false
          : true;
      }
    },
    shouldShowIOSButton() {
      if (this.isMobilePlatform) {
        return /Android/i.test(this.dataFromUserPlatform) ? false : true;
      }
    },
  },
  mounted() {
    this.dataFromUserPlatform = navigator.platform + " " + navigator.userAgent;
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
$maxWidthDesktop: 1280px;
$maxWidthInnerContentDesktop: 1040px;
.showOnMobile {
  display: none;
}
.showOnDesktop {
  display: block;
}
h1 {
  font-size: 64px;
  @include Gilroy-Bold;
  margin-bottom: 0px;
  margin-top: 0px;
}
h3 {
  font-size: 48px;
}
h4 {
  font-size: 32px;
  @include Gilroy-Regular;
}
h5 {
  @include Gilroy-Regular;
  font-size: 24px;
  line-height: 40px;
}
p {
  line-height: 24px;
}
.bold {
  @include Gilroy-Bold;
}
section {
  margin: 68px auto;
  text-align: center;
  &.withBackgroundColor {
    margin: 0px auto;
    padding-top: 68px;
    padding-bottom: 68px;
  }
}
.sectionIntro {
  width: $maxWidthInnerContentDesktop;
  max-width: 85%;
}
.buttonSection {
  margin-top: 52px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  .storeButtonImage {
    width: 165px;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.025);
      box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.2);
    }
  }
  a {
    &:nth-child(1) {
      margin-right: 22px;
    }
    &:nth-child(2) {
      margin-left: 22px;
    }
  }
}
.scorecard {
  background-color: rgba(5, 181, 188, 0.05);
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  > *:not(img) {
    margin-bottom: 24px;
  }
  img {
    width: 382px;
    margin-top: 48px;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  }
}
.dashboard {
  display: flex;
  flex-direction: column;
  width: $maxWidthInnerContentDesktop;
  max-width: 85%;
  > * {
    margin-bottom: 32px;
  }
  .dashboardFirstRow {
    padding-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    > * {
      display: flex;
      align-items: center;
    }
    .left,
    .right {
      img {
        width: 55%;
      }
    }
    .middle {
      justify-content: center;
      img {
        width: 100%;
      }
    }
    .right {
      justify-content: flex-end;
    }
  }
  .dashboardSecondRow {
    padding-top: 100px;
    /* padding-bottom: 32px; */
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    div {
      display: flex;
      align-items: flex-start;
    }
    img {
      max-width: 100%;
    }
    .left,
    .right {
      img {
        width: 55%;
        /* height: auto; */
      }
    }
    .right {
      justify-content: flex-end;
    }
  }
}
.comparePlayersWrapper {
  background-color: rgba(5, 181, 188, 0.05);
  padding-top: 68px;
  .comparePlayers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    width: $maxWidthInnerContentDesktop;
    max-width: 85%;
    margin: 0 auto;
    text-align: left;
    .left {
      padding-top: 68px;
      display: flex;
      flex-direction: column;
    }
    img {
      width: 100%;
    }
  }
}
.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: $maxWidthDesktop;
  max-width: 85%;
  margin: 68px auto 100px auto;
  .testimonial {
    padding-right: 20%;
    padding-left: 20%;
    margin: 0 auto;
    text-align: left;
    img,
    h4 {
      margin-bottom: 22px;
    }
  }
  .testimonial:not(:last-child) {
    border-right: 1px solid black;
  }
}
.buttonSectionDeviceSpecific {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  img {
    width: 165px;
  }
}
.dashboardImagesMobile {
  width: $maxWidthInnerContentDesktop;
  max-width: 85%;
  margin: 32px auto 0px auto;
  .fullCircleImage {
    width: 90%;
    max-width: 400px;
  }
  .halfCirclesWrapper {
    display: flex;
    justify-content: space-between;
    img {
      width: 30%;
      max-width: 180px;
      height: auto;
      align-self: center;
    }
  }
  .aceEaglesWrapper {
    display: flex;
    justify-content: space-around;
    margin: 32px 0px;
    width: 100%;
    img {
      width: 15%;
      max-width: 75px;
      height: auto;
      align-self: center;
    }
  }
  .statsSummaryImage {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .showOnMobile {
    display: block;
  }
  .showOnDesktop {
    display: none;
  }
  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 0px;
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
  }
  section {
    margin: 40px auto;
    &.withBackgroundColor {
      padding: {
        top: 46px;
        bottom: 46px;
      }
    }
  }
  .buttonSection {
    margin-top: 32px;
    a {
      &:nth-child(1) {
        margin-right: 12px;
      }
      &:nth-child(2) {
        margin-left: 12px;
      }
    }
    .storeButtonImage {
      width: 120px;
    }
  }
  .scorecard {
    h1 {
      margin-bottom: 12px;
    }
    h5 {
      font-size: 18px;
    }
    img {
      margin-top: 32px;
      width: 283px;
    }
  }
  .dashboard {
    max-width: 92%;
    > * {
      margin-bottom: 12px;
    }
    h5 {
      font-size: 18px;
    }
  }
  .comparePlayersWrapper {
    padding-top: 40px;
    .comparePlayers {
      grid-template-columns: 1fr;
      text-align: center;
      grid-gap: 18px;
      .left {
        padding-top: 0px;
        h3 {
          font-size: 26px;
          margin-bottom: 12px;
        }
      }
      img {
        width: 92%;
        max-width: 468px;
      }
    }
  }
  .testimonials {
    grid-template-columns: 1fr;
    margin-top: 32px;
    margin-bottom: 32px;
    .testimonial {
      border-right: none !important;
      text-align: center;
      padding: 16px 0px;
      h5 {
        font-size: 18px;
      }
    }
  }
}
</style>
