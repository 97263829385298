<template>
  <div>
    <section class="sectionIntro">
      <h5>
        With Tjing, you have the power to build and manage any kind of
        tournament, league or event in a matter of minutes. Tjing takes care of
        everything so you can focus on hosting your competition.
      </h5>
    </section>
    <section class="builderFeatures">
      <h1>Build</h1>
      <h5>Building competitions with Tjing is effortless.</h5>
      <div class="showOnDesktop">
        <div class="builderFeaturesImages">
          <div class="builderFeaturesImageWithText">
            <img src="@/assets/img/builderSingleEvent.svg" />
            <h5 class="bold">Single event</h5>
          </div>
          <div class="builderFeaturesImageWithText">
            <img src="@/assets/img/builderTournament.svg" />
            <h5 class="bold">Tournament</h5>
          </div>
          <div class="builderFeaturesImageWithText">
            <img src="@/assets/img/builderWeeklyLeague.svg" />
            <h5 class="bold">Weekly league</h5>
          </div>
        </div>
      </div>
      <div class="showOnMobile">
        <div class="builderFeaturesImages">
          <div class="builderFeaturesImageWithText">
            <img src="@/assets/img/builderSingleEvent.svg" />
            <div class="builderFeaturesAsText">
              <h5 class="bold">Single event</h5>
              <span class="dotSeparator">&#8226;</span>
              <h5 class="bold">Tournament</h5>
              <span class="dotSeparator">&#8226;</span>
              <h5 class="bold">Weekly league</h5>
            </div>
          </div>
        </div>
      </div>
      <h5 class="description">
        Whether it’s a one day or weekend event, a National tour or a weekly
        league, Tjing helps you build pretty much any type of competition. The
        possibilities are endless.
      </h5>
      <div class="buttonSection">
        <div class="buttonContainer">
          <router-link tag="button" class="shampoo" :to="{ name: 'builder' }"
            >Start building</router-link
          >
        </div>
      </div>
    </section>
    <section class="appFeatures">
      <h1>Setting new standards</h1>
      <h5>Take advantage of some of Tjing’s revolutionary features.</h5>
      <div class="appFeaturesImages">
        <div class="appFeaturesImageWithText">
          <img src="@/assets/img/verifierShield.svg" />
          <h4 class="bold title">The Verifier™</h4>
          <h6>Effectively reduces the chance of incorrect scores.</h6>
          <StandardSolidButton
            class="demo-button d-lg-none"
            title="Watch Demo"
            :fluid="true"
            :desktopFluidity="true"
            @click="goToWebsite('https://www.youtube.com/watch?v=8--3SXTUuLk')"
          />
          <StandardSolidButton
            class="demo-button d-none d-lg-flex"
            title="Watch Demo"
            :fluid="true"
            :desktopFluidity="true"
            @click="$refs.verifierdemo.open()"
          />
        </div>
        <div class="appFeaturesImageWithText">
          <img src="@/assets/img/signatureCheckmark.svg" />
          <h4 class="bold title">The Signature™</h4>
          <h6>A four digit PIN for players to digitally sign their results.</h6>
          <StandardSolidButton
            class="demo-button d-lg-none"
            title="Watch Demo"
            :fluid="true"
            :desktopFluidity="true"
            @click="goToWebsite('https://www.youtube.com/watch?v=XSkxigU_AIY')"
          />
          <StandardSolidButton
            class="demo-button d-none d-lg-flex"
            title="Watch Demo"
            :fluid="true"
            :desktopFluidity="true"
            @click="$refs.signaturedemo.open()"
          />
        </div>
      </div>
    </section>
    <section class="directPayments">
      <h1>Direct Payments</h1>
      <h5>A complete integrated payment solution for player registrations.</h5>
      <h5 class="bold">Practical for you. Effortless for players.</h5>
      <div class="directPaymentsFeatures">
        <div class="showOnMobile imageWrapper">
          <img src="@/assets/img/builderPaymentMobile.png" />
        </div>
        <div class="texts">
          <div>
            <h5 class="bold">Less admin hours</h5>
            <p>
              Effectively reduces admin hours. Avoid any confusion on whether
              players have paid or not.
            </p>
          </div>
          <div>
            <h5 class="bold">One click refunds</h5>
            <p>
              Enable automatic refunds for when players cancel or set to manual
              and use our one click refund option.
            </p>
          </div>
        </div>
        <div class="showOnDesktop imageWrapper">
          <img src="@/assets/img/builderPaymentMobile.png" />
        </div>
        <div class="texts">
          <div>
            <h5 class="bold">Multiple accounts</h5>
            <p>
              Let your external event organisers receive payments directly to
              their own account.
            </p>
          </div>
          <div>
            <h5 class="bold">Tour Pass option</h5>
            <p>
              If enabled, players can choose the tour pass or just a single
              event when registering to play.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="manageEvents">
      <h1>Manage</h1>
      <h5>
        Live status, players' overview, divisions, groups, leaderboards,
        payments, refunds and much more.
      </h5>
      <div class="manageEventsFeatures">
        <div>
          <img src="@/assets/img/manageFeaturesMarshalls.svg" />
          <h5 class="bold">Marshalls</h5>
          <p>
            Add a Marshall to a group to take the scores. Players scorecards
            become locked and they don’t score or verify.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesCheckIn.svg" />
          <h5 class="bold">Check in</h5>
          <p>
            Set open and close times for a visual overview of who has turned up
            to play and who hasn’t.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesAutomation.svg" />
          <h5 class="bold">Automation</h5>
          <p>
            Set a date and time when you want player registration to open and
            Tjing will make it happen.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesAdmins.svg" />
          <h5 class="bold">Admins</h5>
          <p>
            Give others access to your events so they can help, or even run
            entire events without you.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesLeaderboard.svg" />
          <h5 class="bold">Leaderboard</h5>
          <p>
            When hosting a tour with multiple events, choose to display the
            leaderboard by points, par or throws.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesPlayerRegistration.svg" />
          <h5 class="bold">Player registration</h5>
          <p>
            Standard waiting list or a casual signup where players choose a
            group.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesStartAndSort.svg" />
          <h5 class="bold">Start and sort formats</h5>
          <p>
            Shotgun or teetime. Randomise or sort by results and divisions.
            Override manually at any point.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesFullyEditable.svg" />
          <h5 class="bold">Fully editable</h5>
          <p>
            Easily edit results and stats on any round played. Plus take care of
            tiebreaks.
          </p>
        </div>
        <div>
          <img src="@/assets/img/manageFeaturesDownload.svg" />
          <h5 class="bold">Download</h5>
          <p>
            Access player details and results by downloading comma separated
            files (CSV).
          </p>
        </div>
      </div>
    </section>
    <section class="testimonialContainer">
      <h1>What organisers say</h1>
      <div class="testimonials">
        <div class="testimonial">
          <img src="@/assets/img/quoteSign.svg" />
          <h5>
            Players register, pay and choose a group. The only thing you have to
            do is press the start button.
          </h5>
          <p>Hasse Tegebäck, Onsala DGK</p>
        </div>
        <div class="testimonial">
          <img src="@/assets/img/quoteSign.svg" />
          <h5>A great tool. Stylish, smooth and simply the best.</h5>
          <p>Robert Pozek, Älmhult DGK</p>
        </div>
        <div class="testimonial">
          <img src="@/assets/img/quoteSign.svg" />
          <h5>
            Wonderful service … so easy to use for a weekly league or major
            event.
          </h5>
          <p>Johan Dennefors, Varberg DGK</p>
        </div>
      </div>
    </section>
    <sweet-modal class="adjust video" ref="verifierdemo">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/8--3SXTUuLk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </sweet-modal>
    <sweet-modal class="adjust video" ref="signaturedemo">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/XSkxigU_AIY"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </sweet-modal>
  </div>
</template>

<script>
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";

export default {
  name: "WhyTjingOrgnaiser",
  components: {
    StandardSolidButton,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    goToWebsite(url) {
      window.open(url, "_blank").focus();
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.adjust {
  .sweet-modal {
    &.is-visible {
      max-width: 600px !important;
    }
  }
}
@include buttonStylesWithHoverEffect($buttonContainerWidth: 165px);
$maxWidthDesktop: 1280px;
$maxWidthInnerContentDesktop: 1040px;
.showOnMobile {
  display: none;
}

.showOnDesktop {
  display: block;
}

h1 {
  font-size: 64px;
  @include Gilroy-Bold;
  margin-bottom: 0px;
  margin-top: 0px;
}

h4 {
  font-size: 32px;
  @include Gilroy-Regular;
  margin-bottom: 0px;
}

h5 {
  @include Gilroy-Regular;
  font-size: 24px;
  line-height: 40px;
}

h6 {
  @include Gilroy-Regular;
  font-size: 20px;
  line-height: 30px;
}

p {
  line-height: 24px;
}

.bold {
  @include Gilroy-Bold;
}

section {
  margin: 68px auto;
  text-align: center;
}
.demo-button {
  margin: 18px 0 12px 0;
}

.sectionIntro {
  width: $maxWidthInnerContentDesktop;
  max-width: 85%;
}

.builderFeatures {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(5, 181, 188, 0.05);
  padding: 68px 0px 102px 0px;

  > *:not(.buttonSection) {
    width: $maxWidthInnerContentDesktop;
    max-width: 85%;
    text-align: center;
    margin-bottom: 24px;
  }

  .builderFeaturesImages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: $maxWidthInnerContentDesktop;
    margin-top: 28px;
  }

  h5.description {
    margin-top: 8px;
  }

  button {
    margin-top: 24px;
    margin-bottom: 0px;
    width: auto;
  }
}

.builderFeaturesImageWithText,
.appFeaturesImageWithText {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    margin-bottom: 18px;
  }

  &.desktop {
    display: none;
  }
}

.appFeatures {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: $maxWidthInnerContentDesktop;
    max-width: 85%;
    text-align: center;
    margin-bottom: 24px;
  }

  .appFeaturesImages {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 52px;

    .appFeaturesImageWithText {
      width: 70%;
      margin: 0 auto;

      .title {
        margin-top: 12px;
        margin-bottom: 18px;
      }
    }
  }
}

.directPayments {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(5, 181, 188, 0.05);
  padding-top: 68px;

  h1 {
    margin-bottom: 24px;
  }

  h4 {
    margin-bottom: 12px;
  }

  .directPaymentsFeatures {
    margin-top: 52px;
    width: 100%;
    max-width: $maxWidthDesktop;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    img {
      width: 382px;
    }

    .texts {
      width: 70%;
      margin: 0 auto;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 50px;
      padding: 72px 0px;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.manageEvents {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d2d7dc;
  margin-bottom: 0px;
  padding-bottom: 48px;

  > * {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }

  > h4,
  > p {
    width: $maxWidthInnerContentDesktop;
    margin-left: auto;
    margin-right: auto;
  }

  h5 {
    margin-bottom: 0px;
  }

  .manageEventsFeatures {
    width: $maxWidthDesktop;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    h5 {
      margin-bottom: 10px;
    }

    div {
      width: 72%;
      display: flex;
      flex-direction: column;
      margin: 88px auto 0px auto;

      img {
        height: 48px;
        margin-bottom: 22px;
      }
    }
  }
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: $maxWidthDesktop;
  max-width: 85%;
  margin: 68px auto 100px auto;

  .testimonial {
    padding-right: 20%;
    padding-left: 20%;
    margin: 0 auto;
    text-align: left;

    img,
    h4 {
      margin-bottom: 22px;
    }
  }

  .testimonial:not(:last-child) {
    border-right: 1px solid black;
  }
}

@media (max-width: 768px) {
  .demo-button {
    margin: 24px 0;
  }
  .showOnMobile {
    display: block;
  }
  .showOnDesktop {
    display: none;
  }
  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 0px;
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
  }
  section {
    margin: 40px auto;
  }
  .builderFeatures {
    padding: 40px 0 62px 0;

    h1 {
      margin-bottom: 12px;
    }

    h5 {
      font-size: 18px;
    }

    .builderFeaturesImages {
      grid-template-columns: 1fr;

      .builderFeaturesAsText {
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: space-between;

        h5 {
          font-size: 16px;
        }

        .dotSeparator {
          font-size: 22px;
        }
      }
    }

    button {
      width: 100%;
    }

    .description {
      font-size: 16px;
    }
  }
  .appFeatures {
    h1 {
      margin-bottom: 12px;
    }

    h5 {
      font-size: 18px;
    }

    .appFeaturesImages {
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 32px;
      margin-bottom: 0px;

      .appFeaturesImageWithText {
        width: 100%;
      }

      .title {
        font-size: 26px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }
  .directPayments {
    padding: 40px 0px 52px 0px;

    > * {
      max-width: 85%;
    }

    h1 {
      margin-bottom: 12px;
    }

    h5 {
      font-size: 18px;
    }

    .texts {
      width: 90% !important;
      max-width: 420px;

      h5 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }
    }

    .directPaymentsFeatures {
      grid-template-columns: 1fr;
      margin-top: 28px;

      .imageWrapper {
        border-bottom: 2px solid white;
      }

      .texts {
        grid-gap: 40px;
        padding-top: 40px;
        padding-bottom: 0px;
      }

      img {
        width: 302px;
      }
    }
  }
  .manageEvents {
    padding-bottom: 26px;

    h1 {
      margin-bottom: 12px;
    }

    h5 {
      font-size: 18px;
      line-height: 27px;
    }

    .manageEventsFeatures {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 0px;

      div {
        margin-top: 26px;
        margin-bottom: 16px;
        width: 85%;

        h5 {
          font-size: 20px;
          line-height: 26px;
        }

        img {
          height: 32px;
          margin-bottom: 12px;
        }

        p {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  .testimonials {
    grid-template-columns: 1fr;
    margin-top: 32px;
    margin-bottom: 32px;

    .testimonial {
      border-right: none !important;
      text-align: center;
      padding: 16px 0px;

      h5 {
        font-size: 18px;
      }
    }
  }
}
</style>
